.politic {
  padding: 40px;
  &__wrapper {

  }

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__text {
    text-align: left;
    font-size: 19px;
    line-height: 135%;
    letter-spacing: -0.02em;

    strong {
      font-weight: bold;
    }
  }
}