.advantages {
  position: relative;

  &__title-wrapper {
    overflow: hidden;
  }

  &.need-animation {
    .advantages {
      &__title {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }
    }
  }

  &.animate {
    .advantages {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
    }
  }

  &__slider-item {
    position: relative;
    border-radius: 10px;
    max-width: 388px;
    border: 1px solid var(--green-border-light);

    &_first {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: -2;
        background: {
          image: url('../images/advantages/kpi.png');
          repeat: no-repeat;
          position: bottom;
          size: 100%;
        };
      }
    }

    &_second {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: -2;
        background: {
          image: url('../images/advantages/demotivation.png');
          repeat: no-repeat;
          position: bottom;
          size: 100%;
        };
      }
    }

    &_third {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: -2;
        background: {
          image: url('../images/advantages/competition.png');
          repeat: no-repeat;
          position: bottom;
          size: 100%;
        };
      }
    }
    &_fourth {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: -2;
        background: {
          image: url('../images/advantages/reports.png');
          repeat: no-repeat;
          position: bottom;
          size: 100%;
        };
      }
    }
    &_fifth {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: -2;
        background: {
          image: url('../images/advantages/dashboards.png');
          repeat: no-repeat;
          size: 100%;
          position: bottom;
        };
      }
    }
  }

  &__slider-title {
    font-weight: normal;
    line-height: 1.1em;
    letter-spacing: -0.02em;
  }

  &__slider-subtitle {
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: -0.02em;
  }

  &__slider-arrows {
    display: flex;
    justify-content: space-between;
    width: 120px;
  }
}

@include respond-up('large') {
  .advantages {
    padding: 130px 0;

    &__slider {
      margin-top: 60px;
    }

    &__slider-item {
      padding: 40px;
      margin: 0 6px;
      min-height: 423px;

      &:nth-child(2) {
        transform: translate3d(0, 40px, 0);
      }

      &:nth-child(3) {
        transform: translate3d(0, 30px, 0);
      }

      &:hover {
        .advantages {
          &__slider-description-wrapper {
            opacity: 1;
          }

          &__slider-description {
            transform: translateY(-9px);
            opacity: 1;
            transition-duration: 0.4s;
            transition-delay: 0.2s;
            transition-timing-function: ease-out;
          }
        }
      }
    }

    &__slider-description-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 56px 40px;
      background-color: white;
      opacity: 0;
      transition: var(--default-transition);
      border-radius: 10px;
    }

    &__slider-description {
      position: absolute;
      top: 65px;
      left: 40px;
      right: 40px;
      opacity: 0;
      transition: var(--default-transition);
    }

    &__slider-subtitle {
      margin-top: 20px;
      font-size: 23px;
    }

    &__slider-title {
      font-size: 18px;
    }

    &__slider-arrows-container {
      position: absolute;
      top: 133px;
      right: 0;
    }
  }
}

@include respond-down('medium') {
  .advantages {
    padding: 40px 0;

    &__slider {
      margin-top: 20px;
    }

    &__slider-item {
      padding: 25px;
      min-height: 600px;
    }

    &__slider-subtitle {
      margin-top: 16px;
      font-size: 20px;
    }

    &__slider-title {
      font-size: 16px;
    }

    &__slider-arrows-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
    }

    &__slider-description-wrapper {
      margin-top: 10px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .advantages {
    &__slider-item {
      max-width: 472px;
      margin: 0 5px;
    }
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .advantages {
    &__slider-item {
      max-width: 344px;
      margin: 0 5px;
    }
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .advantages {
    &__slider-item {
      max-width: 375px;
    }
  }
}